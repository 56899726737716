import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Table } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';

import MainLayout from 'containers/Layout/MainLayout';
import { restService } from 'utilities/restService';

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    >
      <Marker position={{ lat: props.latitude, lng: props.longitude }} />
    </GoogleMap>
  ))
);

const ProductsContainer = ({ accessToken }) => {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [productToShow, setProductToShow] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const loadProducts = async (p = 1) => {
    try {
      const response = await restService({
        api: `/admin/products?page=${p}`,
        method: 'GET',
        token: accessToken
      });
      if (response.status !== 200 || !response.data) {
        return;
      }

      setRows(response.data.rows || []);
      setTotal(response.data.count || 0);
    } catch (e) {
      // continue regardless of error
    }
  };

  // const deleteProduct = async productId => {
  //   try {
  //     const response = await restService({
  //       api: `/admin/products/${productId}`,
  //       method: 'DELETE',
  //       token: accessToken
  //     });
  //     if (response.status !== 200) {
  //       console.log(response);
  //       console.log('delete product failed with status: ', response.status);
  //       return;
  //     }

  //     loadProducts(page);
  //   } catch (e) {
  //     console.log('failed to delete products');
  //   }
  // };

  const handleChange = pagination => {
    loadProducts(pagination.current);
  };

  const handleShowMap = product => {
    setProductToShow(product);
    setModalVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Owner',
      render: value => value.user?.name,
      key: 'owner'
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => moment(text).format('YYYY-MM-DD hh:mm:ss')
    },
    {
      title: 'Latitude',
      // dataIndex: 'provider',
      key: 'latitude',
      render: value => value.location?.coordinates[1]
    },
    {
      title: 'Longitude',
      key: 'longitude',
      render: value => value.location?.coordinates[0]
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <button type="button" onClick={() => handleShowMap(record)}>
          Show Map
        </button>
      )
    }
  ];

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <MainLayout>
      <Table
        dataSource={rows}
        pagination={{ pageSize: 20, total }}
        onChange={handleChange}
        columns={columns}
      />
      <Modal
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      >
        {productToShow?.location?.coordinates && (
          <MapWithAMarker
            latitude={productToShow?.location?.coordinates[1]}
            longitude={productToShow?.location?.coordinates[0]}
            googleMapURL="https://maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyAVTIxiHRQY4B8PADKT0ZJ7PwQxRO0lAxw"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        )}
      </Modal>
    </MainLayout>
  );
};

ProductsContainer.propTypes = {
  accessToken: PropTypes.string
};

ProductsContainer.defaultProps = {
  accessToken: ''
};

const mapStateToProps = ({ auth }) => ({
  accessToken: auth.accessToken
});

export default connect(mapStateToProps)(ProductsContainer);
